// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-designer button{
    border: none;
    background: rgb(6 211 30);
    border-radius: 2px;
    font-size: 13px;
    padding: 5px 10px;
    box-shadow: 1px 1px 2px 1px #ada6a6;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Admin.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,mCAAmC;AACvC","sourcesContent":[".save-designer button{\r\n    border: none;\r\n    background: rgb(6 211 30);\r\n    border-radius: 2px;\r\n    font-size: 13px;\r\n    padding: 5px 10px;\r\n    box-shadow: 1px 1px 2px 1px #ada6a6;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
