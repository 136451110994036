// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.login-body{
    font-family: "Arial", sans-serif;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  
    color: #333;
  }
.container {
    width: 100%;
    justify-content: center;
  }
  
  .card {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 35%;
  }
  
  h2 {
    text-align: center;
    color: #333;
    font-size: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  input {
    padding: 10px;
    margin-bottom: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s ease-in-out;
    outline: none;
    color: #333;
  }
  
  input:focus {
    border-color: #ff8c00;
  }
  
  .login-btn {
    background-color: #ff8c00; 
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
 .login-btn:hover {
    background-color: #ff7f00;
  }
  @media(max-width:769px){
    .card{
      width:350px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Login/Login.css"],"names":[],"mappings":";AACA;IACI,gCAAgC;IAChC,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;;IAEjB,WAAW;EACb;AACF;IACI,WAAW;IACX,uBAAuB;EACzB;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,yCAAyC;IACzC,aAAa;IACb,WAAW;EACb;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,6CAA6C;EAC/C;;CAED;IACG,yBAAyB;EAC3B;EACA;IACE;MACE,WAAW;IACb;EACF","sourcesContent":["\r\n.login-body{\r\n    font-family: \"Arial\", sans-serif;\r\n    margin: 0;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    min-height: 100vh;\r\n  \r\n    color: #333;\r\n  }\r\n.container {\r\n    width: 100%;\r\n    justify-content: center;\r\n  }\r\n  \r\n  .card {\r\n    background-color: #fff;\r\n    padding: 30px;\r\n    border-radius: 8px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n    width: 35%;\r\n  }\r\n  \r\n  h2 {\r\n    text-align: center;\r\n    color: #333;\r\n    font-size: 20px;\r\n  }\r\n  \r\n  form {\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n  \r\n  input {\r\n    padding: 10px;\r\n    margin-bottom: 12px;\r\n    border: 1px solid #ddd;\r\n    border-radius: 4px;\r\n    transition: border-color 0.3s ease-in-out;\r\n    outline: none;\r\n    color: #333;\r\n  }\r\n  \r\n  input:focus {\r\n    border-color: #ff8c00;\r\n  }\r\n  \r\n  .login-btn {\r\n    background-color: #ff8c00; \r\n    color: #fff;\r\n    padding: 10px;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s ease-in-out;\r\n  }\r\n  \r\n .login-btn:hover {\r\n    background-color: #ff7f00;\r\n  }\r\n  @media(max-width:769px){\r\n    .card{\r\n      width:350px;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
