import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function Packing() {
  const [SelectWork, setSelectWork] = useState("");
  const [workList, setworkList] = useState([]);
  const [selectedWorkDetails, setSelectedWorkDetails] = useState(null); // State to store selected work details
  const [AccessToken, setAccessToken] = useState("");

  const navigate = useNavigate()

  const logout =()=>{
    localStorage.clear()
    navigate('/login-staff')
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAccessToken(token);
      fetchWorkList(token);
    } else {
      navigate('/login-staff')
    }
  }, []);

  const assignmentId = 'aeqeqjegyefyu31e1'; 

  const fetchWorkList = async (token) => {
    try {
      const response = await axios.get(`https://printzed.ae/get-packing-work/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setworkList(response.data);
    } catch (error) {
      console.log(error, 'Failed to fetch work list.');
    }
  };

  const handleWorkSelection = (e) => {
    const selectedWorkId = e.target.value;
    setSelectWork(selectedWorkId);

    // Find and set details of the selected work
    const selectedWork = workList.find((work) => work.id.toString() === selectedWorkId);
    setSelectedWorkDetails(selectedWork || null);
  };

  const updateWork = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `https://printzed.ae/update-packing-status/${SelectWork}/`,
        { status: 'completed' },
        {
          headers: {
            Authorization: `Bearer ${AccessToken}`,
          },
        }
      );
      // if(response.status)
        alert(response)
    } catch (err) {
      alert(err.response.data.error , "Failed to update status.");
      // console.log()
    }
  };

  return (
    <div>
      <div>
        <div className="manage-staff">
          <h4>Packing <i class="bi bi-box-arrow-right" onClick={logout}></i></h4>
        </div>
        <div className="add-staff">
          <div className="row">
            <div className="col-6">
              <div className="assign-staff">
                <div className="row">
                  <div className="col-6">
                    <select onChange={handleWorkSelection}>
                      <option value="">Select Work</option>
                      {workList.map((work, index) => (
                        <option key={index} value={work.id}>
                          {work.product_name} | ({work.company_name})
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-6">
                    <div className="save-designer">
                      <button onClick={updateWork}>Complete</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br></br>
            <br></br>

            <div className="">
              {selectedWorkDetails && (
              <div className="work-details">
              <h5>Work Details</h5>
              <p><strong>Designer Name:</strong> {selectedWorkDetails.designer_name}</p>
              <p><strong>Product Name:</strong> {selectedWorkDetails.product_name}</p>
              <p><strong>Company Name:</strong> {selectedWorkDetails.company_name}</p>
            
              
              <p><strong>Assigned At:</strong> {new Date(selectedWorkDetails.assigned_at).toLocaleString()}</p>
              <p><strong>Delivery Date:</strong> {selectedWorkDetails.delivery_date}</p>
              <p><strong>Sales Person:</strong> {selectedWorkDetails.sales_person}</p>
              {/* <p><strong>Sales Person:</strong> {selectedWorkDetails.sales_person}</p> */}
              <p><strong>Client Name:</strong> {selectedWorkDetails.client_name}</p>
              <p><strong>Phone Number:</strong> {selectedWorkDetails.phone_number}</p>
              <p><strong>Quantity:</strong> {selectedWorkDetails.quantity}</p>
              <p><strong>Price:</strong> {selectedWorkDetails.price}</p>
              <p><strong>Total Price:</strong> {selectedWorkDetails.total_price}</p>
            </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Packing;
