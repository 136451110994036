// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-staff h4{
    padding: 10px;
    box-shadow: 0px 0px 3px grey;
    margin: 10px;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
}
select{
    width: 100%;
    padding: 2px 3px;
    border: 1px solid #a9a3a3;
    border-radius: 3px;
    box-shadow: 0px 1px 1px 0px #b1b1b1;
}
.add-staff{
    margin: 10px;
    padding: 24px;
}
.add-staff .row>* ,.add-staff-modal .row>*{
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
.add-user{
    text-align: -webkit-right;
}
.add-user button{
    border: none;
    background: orange;
    border-radius: 2px;
    font-size: 13px;
    padding: 5px;
    box-shadow: 1px 1px 2px 1px #ada6a6;
}
.modal-content{
    background: white !important;
}
.save-staff{
    text-align: -webkit-right;
}
.save-staff button{
    border: none;
    background: rgb(6 211 30);
    border-radius: 2px;
    font-size: 13px;
    padding: 5px 10px;
    box-shadow: 1px 1px 2px 1px #ada6a6;
    margin-top: 20px;
}
.user-designation-container{
    margin: 0px 20px;
}
.str{
padding: 10px 0px;
}
.order-status-checking{
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ManageStaff/ManageStaff.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4BAA4B;IAC5B,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,mCAAmC;AACvC;AACA;IACI,YAAY;IACZ,aAAa;AACjB;AACA;IACI,4BAA4B;IAC5B,6BAA6B;EAC/B;AACF;IACI,yBAAyB;AAC7B;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,mCAAmC;AACvC;AACA;IACI,4BAA4B;AAChC;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,mCAAmC;IACnC,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;AACA,iBAAiB;AACjB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".manage-staff h4{\r\n    padding: 10px;\r\n    box-shadow: 0px 0px 3px grey;\r\n    margin: 10px;\r\n    border-radius: 3px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\nselect{\r\n    width: 100%;\r\n    padding: 2px 3px;\r\n    border: 1px solid #a9a3a3;\r\n    border-radius: 3px;\r\n    box-shadow: 0px 1px 1px 0px #b1b1b1;\r\n}\r\n.add-staff{\r\n    margin: 10px;\r\n    padding: 24px;\r\n}\r\n.add-staff .row>* ,.add-staff-modal .row>*{\r\n    padding-left: 5px !important;\r\n    padding-right: 5px !important;\r\n  }\r\n.add-user{\r\n    text-align: -webkit-right;\r\n}\r\n.add-user button{\r\n    border: none;\r\n    background: orange;\r\n    border-radius: 2px;\r\n    font-size: 13px;\r\n    padding: 5px;\r\n    box-shadow: 1px 1px 2px 1px #ada6a6;\r\n}\r\n.modal-content{\r\n    background: white !important;\r\n}\r\n.save-staff{\r\n    text-align: -webkit-right;\r\n}\r\n.save-staff button{\r\n    border: none;\r\n    background: rgb(6 211 30);\r\n    border-radius: 2px;\r\n    font-size: 13px;\r\n    padding: 5px 10px;\r\n    box-shadow: 1px 1px 2px 1px #ada6a6;\r\n    margin-top: 20px;\r\n}\r\n.user-designation-container{\r\n    margin: 0px 20px;\r\n}\r\n.str{\r\npadding: 10px 0px;\r\n}\r\n.order-status-checking{\r\n    margin-top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
