import React, { useState, useEffect } from 'react';
import './ManageStaff.css';
import axios from "axios";
import { Navigate, useNavigate } from 'react-router-dom';

function ManageStaff() {
  const [staffList, setStaffList] = useState([]);
  const [newStaffName, setNewStaffName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [selectedStaff, setSelectedStaff] = useState('');
  const [designation, setDesignation] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [userDesignations, setUserDesignations] = useState([]);
  const [productList,setProductList] = useState([])
  const [selectedTrackingId, setSelectedTrackingId] = useState('');
  const [productStatus,setproductStatus] = useState([])
  
  const navigate = useNavigate()

  const logout =()=>{
    localStorage.clear()
    navigate('/login-staff')
  }

  // Fetch staff list and mapped user designations on mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/main-admin')
      setAccessToken(token);
      fetchStaffList(token);
      fetchUserDesignations(token);
      fetchProductList(token);
    } else {
     navigate('/login-staff')
    }
  }, []);

  const fetchStaffList = async (token) => {
    try {
      const response = await axios.get('https://printzed.ae/list-staff/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setStaffList(response.data);
    } catch (error) {
      handleAPIError(error, 'Failed to fetch staff list.');
    }
  };

  const fetchUserDesignations = async (token) => {
    try {
      const response = await axios.get('https://printzed.ae/mapped-list/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setUserDesignations(response.data);
    } catch (error) {
      handleAPIError(error, 'Failed to fetch user designations.');
    }
  };

  const handleAddUser = async () => {
    if (!newStaffName || !newPassword) {
      setError('Username and password are required.');
      return;
    }

    try {
      const response = await axios.post(
        'https://printzed.ae/create/',
        { username: newStaffName, password: newPassword },
        { headers: { 'Authorization': `Bearer ${accessToken}` } }
      );
      setSuccess('User added successfully.');
      setNewStaffName('');
      setNewPassword('');
      fetchStaffList(accessToken); // Refresh staff list
    } catch (error) {
      handleAPIError(error, 'Failed to add user.');
    }
  };

  const handleMapDesignation = async () => {
    if (!selectedStaff || !designation) {
      setError('Please select a staff and designation.');
      return;
    }

    try {
      const response = await axios.post(
        'https://printzed.ae/map-designation/',
        { uuid: selectedStaff, designation },
        { headers: { 'Authorization': `Bearer ${accessToken}` } }
      );
      setSuccess('Designation mapped successfully.');
      setSelectedStaff('');
      setDesignation('');
      fetchUserDesignations(accessToken); // Refresh mapped user list
    } catch (error) {
      handleAPIError(error, 'Failed to map designation.');
    }
  };

  const handleDelete = async (userId) => {
  if(userId){
    try {
      await axios.delete(`https://printzed.ae/delete-designation/${userId}/`, {
        headers: { 'Authorization': `Bearer ${accessToken}` },
      });
      setUserDesignations(userDesignations.filter((user) => user.id !== userId));
      fetchUserDesignations(accessToken);
    } catch (error) {
      handleAPIError(error, 'Failed to delete designation.');
    }
  }
  };

  const handleAPIError = (error, defaultMessage) => {
    const errorMessage = error.response?.data?.message || defaultMessage;
    setError(errorMessage);
    if (error.response?.status === 401 || error.response?.status === 403) {
      // localStorage.clear();
      // window.location.href = '/login-staff';
    }
  };

  const clearMessages = () => {
    setTimeout(() => {
      setError('');
      setSuccess('');
    }, 3000);
  };

  useEffect(() => {
    if (error || success) clearMessages();
  }, [error, success]);


  const fetchProductList = async (token) => {
    try {
      const response = await axios.get('https://printzed.ae/get-all-sales-orders/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setProductList(response.data);
    } catch (error) {
      console.log(error, 'Failed to fetch staff list.');
    }
  };

  const handleSelectionChange = async (e) => {
    const trackingId = e.target.value;
    setSelectedTrackingId(trackingId);
    console.log(`Selected Tracking ID: ${trackingId}`);
  
    if (trackingId) {
      try {
        // Example: Fetch additional details for the selected tracking ID
        const response = await axios.get(`https://printzed.ae/assignments/status/${trackingId}/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        console.log('Fetched Order Details:', response.data.assignments);
        setproductStatus(response.data);
        // Add your logic here to handle the response, e.g., update state or display details
      } catch (error) {
        console.error('Error fetching order details:', error);
        // Handle the error, e.g., show a notification to the user
      }
    }
  };
  
 

  return (
    <div>
      <div className="manage-staff">
        <h4>Manage Staff <i class="bi bi-box-arrow-right" onClick={logout}></i></h4>
      </div>
      <div className="add-staff">
        <div className="row">
          <div className="col-6">
            <div className="assign-staff">
              <div className="row">
                <div className="col-6">
                  <select onChange={(e) => setSelectedStaff(e.target.value)}>
                    <option value="">Select Staff</option>
                    {staffList.map((staff) => (
                      <option key={staff.id} value={staff.uuid}>
                        {staff.username}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6">
                  <select
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                  >
                    <option value="">Select Designation</option>
                    <option value="sales">Sales</option>
                    <option value="admin">Admin</option>
                    <option value="designer">Designer</option>
                    <option value="production">Production</option>
                    <option value="packing">Packing</option>
                  </select>
                </div>
              </div>
              <div className="save-staff">
                <button onClick={handleMapDesignation}>Map Designation</button>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="add-user">
              <button data-bs-toggle="modal" data-bs-target="#exampleModal">
                Add New User
              </button>
            </div>
          </div>
        </div>

        <div className='row'>
              <div className='col-6'>
                    <div className='row'>
                        <div className='col-6'>
                        <select
        id="sales-order-dropdown"
        value={selectedTrackingId}
        onChange={handleSelectionChange}
      >
        <option value="" disabled>
          -- Select Client --
        </option>
        {productList.map((order) => (
          <option key={order.tracking_id} value={order.tracking_id}>{order.client_name}
          </option>
        ))}
      </select>

      <br>
      </br>
      <div>
        {productStatus.assignments && productStatus.tracking_id && (
          <div className='order-status-checking'>
            {productStatus.assignments.map((assignment,index) => (
              <div key={index}>
                <div className='str'><strong>Product Name:</strong> {assignment.product_name}</div>
                
                <div className='str'><strong>Status:</strong> {assignment.sales_status}</div>
              </div>
            ))}
            
            <br />
           
          </div>
        )}
      </div>
                          </div>
                          {/* <div className='col-6'>
                                <button onClick={handleAssignTrackingId}>Assign Tracking ID</button>
                          </div> */}
                    </div>
              </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add User
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body add-staff-modal">
              <div className="row">
                <div className="col-6">
                  <input
                    placeholder="Enter Staff Name"
                    value={newStaffName}
                    onChange={(e) => setNewStaffName(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  <input
                    placeholder="Enter Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
              </div>
              {error && <p className="error-message">{error}</p>}
              {success && <p className="success-message">{success}</p>}
              <div className="save-staff">
                <button onClick={handleAddUser}>Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="user-designation-container">
        <h3>Mapped Users</h3>
        <div className="table-responsive">
          <table className="position-table">
            <thead>
              <tr>
                <th>User Name</th>
                <th>Designation</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {userDesignations.map((user) => (
                <tr key={user.id}>
                  <td>{user.username}</td>
                  <td>{user.designation}</td>
                  <td>
                    
                      <i onClick={() => handleDelete(user.uuid)} class="bi bi-trash3"></i>
                   
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      
    </div>
  );
}

export default ManageStaff;
