import React, { useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';

function Login() {

  const navigate = useNavigate();



  // State to manage the form data
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from reloading the page

    // Prepare data to send to the backend
    const loginData = { username, password };

    try {
      const response = await fetch('https://printzed.ae/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      const data = await response.json();

      if (response.ok) {
        // Store the access token and other details
        localStorage.setItem('token', data.access); // Use the access token
        localStorage.setItem('refresh', data.refresh); // Optionally store the refresh token
       localStorage.setItem('designation', data.designation || 'None'); // Store designation if available
        const des = localStorage.getItem('designation')
        // Redirect to the admin dashboard or another page
        if(des=='None'){
          navigate('/main-admin' , { replace: true }) ;
        }
        else{
          navigate(`/${des}`, { replace: true });
          
        }
        
      } else {
        // Set error from the server response or use a default message
        setError(data.message || 'Invalid username or password.');
      }
    } catch (error) {
      // Handle unexpected errors
      console.error('Login error:', error);
      setError('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className='login-body'>
      <div className="container">
        <div className="card">
          <h2>Staff Login</h2>
          <form onSubmit={handleSubmit}>
            <input 
              type="text" 
              id="username" 
              name="username" 
              placeholder="Username" 
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)} 
            />
            <input 
              type="password" 
              id="password" 
              name="password" 
              placeholder="Password" 
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)} 
            />
            {error && <p className="error-message">{error}</p>}
            <button className='login-btn' type="submit">Login</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
