import React, { useEffect , useState, useRef } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import './Pdf.css'
import { useNavigate } from 'react-router-dom';


const Pdf = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    clientName: '',
    phoneNumber: '',
    trackId: '',
    address: '',
    deliveryDate: '',
    totalBalance: '',
    salesPerson: '',
  });

  const [products, setProducts] = useState([{ productName: '', quantity: '', price: '' }]);
  const formRef = useRef(); // Reference to the form container
  const cot = useRef();
  const invo = useRef();
  const navigate = useNavigate()


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // navigate('/sales')
    } else {
      navigate('/login-staff')
    }
  }, []);

const logout =()=>{
  localStorage.clear()
  navigate('/login-staff')
}
const handleFormChange = (e) => {
  const { name, value } = e.target;

  // Update the form data
  const updatedFormData = { ...formData, [name]: value };

  // Check if the changed field is related to price or amount
  if (name === "totalBalance") {
      const price = parseFloat(value) || 0; // Convert to a number or default to 0
      const vat = price * 0.05; // Calculate 5% VAT
      const totalBalance = price + vat; // Calculate total balance including VAT

      // Update VAT and total balance in the form data
      updatedFormData["vat"] = vat.toFixed(2); // Format to 2 decimal places
      updatedFormData["vat_total"] = totalBalance.toFixed(2); // Format to 2 decimal places
  }
  if (name === "deliveryDate") {
    const date = new Date(value);
    const formattedDate = date.toISOString().slice(0, 10);
    updatedFormData["deliveryDate"] = formattedDate;

    // Calculate the difference in days
    const today = new Date();
    const dayDifference = Math.ceil((date - today) / (1000 * 60 * 60 * 24)); // Convert ms to days

    // Add the calculated difference to updatedFormData (if needed)
    updatedFormData["daysDifference"] = dayDifference;

    console.log(`Days difference: ${dayDifference}`);
}


  // Update the state
  setFormData(updatedFormData);

  console.log('ss',updatedFormData); // Log for debugging
};


const handleProductChange = (index, e) => {
  const { name, value } = e.target;

  const updatedProducts = [...products];

  // Update the value of the changed field
  updatedProducts[index][name] = value;

  // Check if the changed field is "price" or "quantity" and recalculate values if necessary
  if (name === "price" || name === "quantity") {
      const price = parseFloat(updatedProducts[index]["price"]) || 0; // Convert price to a number or default to 0
      const quantity = parseInt(updatedProducts[index]["quantity"]) || 0; // Convert quantity to a number or default to 0

      // Calculate the total cost based on quantity
      const totalPrice = price * quantity;

      // Calculate tax and VAT
      const tax = totalPrice * 0.05; // 5% tax
      const vat = totalPrice * 0.03; // 3% VAT

      // Update the product fields
      updatedProducts[index]["taxable"] = tax.toFixed(2); // Format to 2 decimal places
      updatedProducts[index]["vat"] = vat.toFixed(2);
      const total = totalPrice + tax + vat;
      updatedProducts[index]["total"] = total.toFixed(2);
  }

  // Update the state
  setProducts(updatedProducts);

  console.log(updatedProducts);
};


const generateTrackId = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomTrackId = '';
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomTrackId += characters[randomIndex];
  }
  setFormData((prev) => ({ ...prev, trackId: randomTrackId }));
};




  const addProductRow = () => {
    setProducts([...products, { productName: '', quantity: '', price: '' }]);
  };

  const removeProductRow = (index) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };

  const generatePDF = async () => {
    const formElement = formRef.current;

    html2canvas(formElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 190;
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("form.pdf");
    });
  };


  const invoiceGeneration = (e) => {
    e.preventDefault();
    const formElement = invo.current;
  
    html2canvas(formElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
  
      // Custom page dimensions in mm
      const pageWidth = 210; // Standard A4 width in mm
      const pageHeight = 400; // Increased page height
      const imgWidth = pageWidth - 20; // Adjust for margins
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
  
      // Create PDF with custom dimensions
      const pdf = new jsPDF('p', 'mm', [pageWidth, pageHeight]);
  
      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
  
      pdf.save("form.pdf");
    });
  };
  
  const quotation = (e) => {
    e.preventDefault();
    const formElement = cot.current;
  
    html2canvas(formElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
  
      // Custom page dimensions in mm
      const pageWidth = 210; // Standard A4 width in mm
      const pageHeight = 400; // Increased page height
      const imgWidth = pageWidth - 20; // Adjust for margins
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
  
      // Create PDF with custom dimensions
      const pdf = new jsPDF('p', 'mm', [pageWidth, pageHeight]);
  
      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
  
      pdf.save("form.pdf");
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the form data including products and other fields
    const orderData = {
      trackId: formData.trackId,
      companyName: formData.companyName,
      clientName: formData.clientName,
      phoneNumber: formData.phoneNumber,
      address: formData.address,
      deliveryDate: formData.deliveryDate,
      totalBalance: formData.totalBalance,
      salesPerson: formData.salesPerson,
      products: products, // Include products in the request
    };

    try {
      // Send order data to the backend to save it in the database
      const response = await fetch('https://printzed.ae/create-order/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Order created successfully:', data);

        // Generate PDF after successfully submitting the form
        generatePDF();
      } else {
        const errorData = await response.json();
        console.error('Error creating order:', errorData);
      }
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };

  return (
    
    <div >
              <div className="manage-staff">
          <h4>Sales <i class="bi bi-box-arrow-right" onClick={logout}></i></h4>
          
        </div>

        <div style={{textAlignLast:"right",padding:"10px"}}>
            <span className='invoice' onClick={invoiceGeneration}>Download Invoice</span>
        </div>
        <div className="form-container">
      <form className="form" onSubmit={handleSubmit}>
        {/* Input Form Section */}
        <div className="row allrow">
          <div className="col-12 col-sm-6 col-md-4">
            <label>Company Name</label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleFormChange}
              placeholder="Enter company name"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <label>Client Name</label>
            <input
              type="text"
              name="clientName"
              value={formData.clientName}
              onChange={handleFormChange}
              placeholder="Enter client name"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <label>Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleFormChange}
              placeholder="Enter phone number"
            />
          </div>
        </div>

        {/* Track ID Section */}
        <label>Track ID</label>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <input
            type="text"
            name="trackId"
            value={formData.trackId}
            onChange={handleFormChange}
            placeholder="Enter Track ID"
          />
          <button
            type="button"
            onClick={generateTrackId}
            style={{
              padding: "13px 10px",
              fontSize: "14px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              marginBottom:"4px",
            }}
          >
            Generate
          </button>
        </div>

        {/* Products Section */}
        <label>Products</label>
        {products.map((product, index) => (
          <div className="row allrow" key={index}>
            <div className="col-12 col-sm-6 col-md-3">
              <input
                type="text"
                name="productName"
                value={product.productName}
                onChange={(e) => handleProductChange(index, e)}
                placeholder="Product Name"
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <input
                type="number"
                name="quantity"
                value={product.quantity}
                onChange={(e) => handleProductChange(index, e)}
                placeholder="Quantity"
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <input
                type="number"
                name="price"
                value={product.price}
                onChange={(e) => handleProductChange(index, e)}
                placeholder="Price"
              />
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <button type="button" onClick={addProductRow} className="btn-add">+</button>
              {products.length > 1 && (
                <button type="button" onClick={() => removeProductRow(index)} className="btn-remove">-</button>
              )}
            </div>
          </div>
        ))}

        {/* Other Fields */}
        <label>Total Balance</label>
        <input
          type="number"
          name="totalBalance"
          value={formData.totalBalance || ""}
          onChange={handleFormChange}
          placeholder="Enter total balance"
        />
        <label>Sales Person</label>
        <input
          type="text"
          name="salesPerson"
          value={formData.salesPerson || ""}
          onChange={handleFormChange}
          placeholder="Enter Salesperson name"
        />
        <label>Address Details</label>
        <textarea
          name="address"
          value={formData.address}
          onChange={handleFormChange}
          placeholder="Enter address details"
        />
        <label>Approximate Delivery Date</label>
        <input
          type="date"
          name="deliveryDate"
          value={formData.deliveryDate}
          onChange={handleFormChange}
        />

        {/* Submit Buttons */}
        <button className="submit-btn" type="submit">Generate PDF</button>
        <button className="submit-btn" type="button">Sales Quotation</button>
      </form>
    </div>

      <div className='pdf-temp' ref={formRef} style={{ position: 'relative', padding: '20px', backgroundColor: '#fff', marginTop: '20px', height: '100%' }}>
        {/* Background Image */}
        <img
          src='/images/footer/LOGO 1.png'
          alt="Background"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '80%',
            height: 'auto',
            opacity: 0.1,
            transform: 'translate(-50%, -50%)',
            zIndex: 0,
          }}
        />

        {/* Content Section */}
        <div style={{ position: 'relative', zIndex: 1 }}>
          
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div style={{ fontWeight: 'bold', fontSize: '28px', marginBottom: '4px' }}>Printzed</div>
              <p style={{ fontSize: '16px', margin: '2px 0 5px 0px' }}>Date: {new Date().toLocaleDateString()}</p>
            </div>
            <img style={{ width: '180px', objectFit: 'contain' }} src='/images/footer/LOGO 1.png' alt="Logo" />
          </div>
          
          <hr />
          <p style={{ fontSize: '23px', fontWeight: '500', margin: '0px 0px 26px 0px' }}>{formData.companyName}</p>
          <p style={{ fontSize: '20px', margin: '0px 0px 10px 0px' }}>Track ID &nbsp; : &nbsp; {formData.trackId}</p>
          <p style={{ fontSize: '20px', margin: '0px 0px 10px 0px' }}>Delivery Date &nbsp; : &nbsp; {formData.deliveryDate}</p>
          <p style={{ fontSize: '20px', margin: '0px 0px 10px 0px' }}>Salesperson  &nbsp;: &nbsp; {formData.salesPerson}</p>
          
          <h5 style={{ fontSize: '20px', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold' }}>Product Details:</h5>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0', fontSize: '20px' }}>Item No.</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0', fontSize: '20px' }}>Description</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor: '#f0f0f0', fontSize: '20px' }}>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: '20px' }}>{index + 1}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', fontSize: '20px' }}>{product.productName}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: '20px' }}>{product.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div ref={cot}
      style={{
        width: "100%",
        
        // maxWidth: "800px",
        margin: "auto",
        marginTop: "20px",
        border: "1px solid #ccc",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        color: "#000",
        background: "#fff",
        position: "relative",
      }}
    >
      
      {/* Header with Logo and QR Code */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <img
            src="/images/Navbar/LOGO 2.png"
            alt="Logo"
            style={{ height: "120px" }}
          />
        </div>
<div style={{"display":"flex"}}>
<div style={{ textAlign: "right" }}>
          <p>برينتزد لخدمات التصميم الفني للمطبوعات  </p>
          <p>PRINTZED DESIGN & ARTWORK SERVICES</p>
          <p style={{color:"#d8c665"}}>TRN: 104255388100003</p>
        </div>
        <div>

        </div>
</div>
      </div>

      {/* Title */}
      <h2
        style={{
          textAlign: "center",
          color: "#0f4b37",
          margin: "20px 0",
        }}
      >
        SALES QUOTATION
      </h2>
      <img
          src='/images/Navbar/LOGO 2.png'
          alt="Background"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '80%',
            height: 'auto',
            opacity: 0.1,
            transform: 'translate(-50%, -50%)',
            zIndex: 0,
          }}
        />

      {/* Customer Details and Invoice Information */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <div
          style={{
            flex: 1,
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          <p>
            <strong>Customer Code:</strong> CT00
            <span style={{ float: "right" }}>CT001280</span>
          </p>
          <p>Printzed Design and Artwork Services</p>
          <p>d</p>
          <p>Mob: +971 56 3303837</p>
          <p>13 Shikah Noora Building, 1 St</p>
          <p>Dubai - UAE</p>
        </div>

        <div
          style={{
            flex: 1,
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          <p>
            <strong>Date:</strong> <span>{new Date().toLocaleDateString()}</span>
          </p>
          <p>
            <strong>Time:</strong> {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}


          </p>
          <p>
            <strong>SQ No:</strong> 4394
          </p>
          <p>
            <strong>LPO No:</strong>
          </p>
          <p>
            <strong>Artwork Created By:</strong>
          </p>
        </div>
      </div>

      {/* Sales Info */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          margin: "20px 0",
        }}
      >
        <div
          style={{
            flex: 1,
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          <p>
            <strong>Sales Rep:</strong> {formData.salesPerson}
          </p>
        </div>
        <div
          style={{
            flex: 1,
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          <p>
            <strong>Payment Terms:</strong> Cash
          </p>
        </div>
        <div
          style={{
            flex: 1,
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          <p>
            <strong >Delivery Time:</strong> {formData.daysDifference} Working Days
          </p>
        </div>
      </div>

      {/* Items Table */}
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          textAlign: "left",
          marginBottom: "20px",
          
        }}
      >
        <thead
          style={{
            background: "#0f4b37",
            color: "black",
           
          }}
        >
          <tr>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>
              Item Code
            </th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>
              Description
            </th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>QTY</th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>Rate</th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>
              Taxable
            </th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>VAT</th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>Total</th>
          </tr>
        </thead>
        <tbody>
        {products.map((product, index) => (
          <tr key={index}>
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>{index+1}</td>
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>
            {product.productName}
            </td>
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>{product.quantity}</td>
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>{product.price}</td>
            
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>{product.taxable}</td>
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>{product.vat}</td>
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>{product.total}</td>
          </tr>
        ))}
        </tbody>
      </table>


              {/* Total Summary */}
              <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <table
            style={{
              border: "1px solid #ccc",
              borderCollapse: "collapse",
              width: "40%",
              textAlign: "left",
              fontSize: "14px",
            }}
          >
            <tbody>
              <tr>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  SubTotal:
                </td>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  AED {formData.totalBalance}
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  Discount:
                </td>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  AED 0.00
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  Taxable Value:
                </td>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  AED {formData.totalBalance}
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  VAT(5.0%):
                </td>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  AED {formData.vat}
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  Total:
                </td>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  AED {formData.vat_total}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Quote Validity */}
        <p
          style={{
            color: "red",
            textAlign: "right",
            marginTop: "10px",
            fontWeight: "bold",
          }}
        >
          Quote Valid for {formData.daysDifference} Days
        </p>

            {/* Footer Section */}
            <div style={{ marginTop: "20px" }}>
        {/* Bank Details Section */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          {/* First Bank Details */}
          <div
            style={{
              flex: 1,
              border: "1px solid #ccc",
              padding: "10px",
            }}
          >
            <h4
              style={{
                textAlign: "center",
                marginBottom: "10px",
                backgroundColor: "#f5f5f5",
                padding: "5px",
                fontSize: "16px",
              }}
            >
              BANK DETAILS
            </h4>
            <p>Account Holder: PRINTZED DESIGN & ARTWORK SERVICES</p>
            <p>IBAN: AE070860000009112383159</p>
            <p>BIC: WIOBAEADXXX</p>
            <p>Bank: First Abu Dhabi Bank (FAB)</p>
          </div>

          {/* Second Bank Details */}
          {/* <div
            style={{
              flex: 1,
              border: "1px solid #ccc",
              padding: "10px",
            }}
          >
            <h4
              style={{
                textAlign: "center",
                marginBottom: "10px",
                backgroundColor: "#f5f5f5",
                padding: "5px",
                fontSize: "16px",
              }}
            >
              BANK DETAILS
            </h4>
            <p>Beneficiary Name: Tezkar Promotional Gifts Preparing LLC</p>
            <p>Bank: Mashreq Bank</p>
            <p>Account: 019100104999</p>
            <p>IBAN: AE250330000019100104999</p>
            <p>Swift Code: BOMLAEAD</p>
            <p>Branch: Al Rigga BR, Dubai</p>
          </div> */}
        </div>



      </div>
      <div style={{display:"flex", justifyContent:"space-between", marginTop:"40px"}}>
            <div>
            <p style={{marginBottom:"3px"}}>T: +971 4 297 9792</p>
              <p  style={{marginBottom:"3px"}}>M: +971 56 330 3837</p>
              <p  style={{marginBottom:"3px"}}> E: sales@printzed.ae </p>
              <p  style={{marginBottom:"3px"}}> W: www.printzed.ae L: Dubai UAE.</p>
            </div>
            <div>
            <img style={{height:"100px", width:"100px",objectFit:"contain"}} src="./images/locationQR.png"></img>
            </div>
      </div>

    </div>


    <div ref={invo}
      style={{
        fontFamily: "Arial, sans-serif",
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        border: "1px solid #ddd",
        borderRadius: "5px",
        margin:"10px"
      }}
    >
      {/* Header */}
      <div style={{ textAlign: "center", marginBottom: "20px" , borderBottom: "1px solid #ddd" }}>
        <h1 style={{ margin: "0", color: "#333" }}>TAX INVOICE</h1>
        <p style={{ margin: "5px 0", color: "#555" }}>
          PRINTZED DESIGN & ARTWORK SERVICES
        </p>
        <p style={{ margin: "5px 0", color: "#555" }}>
          Near Canadian Hospital - Abu Hail, Deira Dubai, U.A.E
        </p>
        <p style={{ margin: "5px 0", color: "#555" }}>+971563303837</p>
        <p style={{ margin: "5px 0", color: "#555" }}>printzadvt@gmail.com</p>
        <p style={{ margin: "5px 0", color: "#555" }}>www.printzed.ae</p>
      </div>

      {/* Invoice Details */}
      <div style={{ marginBottom: "20px" , borderBottom: "1px solid #ddd"}}>
        <p>
          <strong>Invoice#:</strong> INV-PZ002710
        </p>
        <p>
          <strong>Invoice Date:</strong>  {new Date().toLocaleDateString()}
          </p>
        <p>
          <strong>Terms:</strong> Due On Receipt
        </p>
        <p>
          <strong>Due Date:</strong> {formData.dayDifference}
        </p>
        <p>
          <strong>Sales Person:</strong> {formData.salesPerson}
        </p>
      </div>

      {/* Client Details */}
      <div style={{ marginBottom: "20px" }}>
        <h3 style={{ margin: "0 0 10px", color: "#333" }}>Client Details</h3>
        <p style={{ margin: "5px 0", color: "#555" }}>
        {formData.companyName}
        </p>
        <p style={{ margin: "5px 0", color: "#555" }}>
        {formData.address}
        </p>
        <p style={{ margin: "5px 0", color: "#555" }}>{formData.phoneNumber}</p>
        <p style={{ margin: "5px 0", color: "#555" }}>TRN: 104131637100003</p>
      </div>

      {/* Table */}
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          textAlign: "left",
          marginBottom: "20px",
          
        }}
      >
        <thead
          style={{
            background: "#0f4b37",
            color: "black",
           
          }}
        >
          <tr>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>
              Item Code
            </th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>
              Description
            </th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>QTY</th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>Rate</th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>
              Taxable
            </th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>VAT</th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>Total</th>
          </tr>
        </thead>
        <tbody>
        {products.map((product, index) => (
          <tr key={index}>
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>{index+1}</td>
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>
            {product.productName}
            </td>
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>{product.quantity}</td>
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>{product.price}</td>
            
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>{product.taxable}</td>
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>{product.vat}</td>
            <td style={{ border: "1px solid #ccc", padding: "10px" }}>{product.total}</td>
          </tr>
        ))}
        </tbody>
      </table>

      {/* Summary */}
      <div style={{ justifyItems: "right", marginBottom: "20px" }}>
      <table
            style={{
              border: "1px solid #ccc",
              borderCollapse: "collapse",
              width: "40%",
              textAlign: "left",
              fontSize: "14px",
            }}
          >
            <tbody>
              <tr>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  SubTotal:
                </td>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  AED {formData.totalBalance}
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  Discount:
                </td>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  AED 0.00
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  Taxable Value:
                </td>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  AED {formData.totalBalance}
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  VAT(5.0%):
                </td>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  AED {formData.vat}
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  Total:
                </td>
                <td style={{ border: "1px solid #ccc", padding: "5px" }}>
                  AED {formData.totalBalance}
                </td>
              </tr>
            </tbody>
          </table>
      </div>

      {/* Footer */}
      <div style={{ textAlign: "center", borderTop: "1px solid #ddd", paddingTop: "10px" }}>
        <p>Looking forward to your business.</p>
        <p>Payment: 75% Advance and 25% on Delivery</p>
        <p>Delivery & Installation after LPO/Approval</p>
      </div>
    </div>

    </div>
  );
};

export default Pdf;
